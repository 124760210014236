var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("header", [
      _c("div", { staticClass: "info" }),
      _c("div", { staticClass: "actions" }, [
        _c(
          "div",
          {
            staticClass: "logout",
            on: {
              click: function ($event) {
                return _vm.$emit("onLogout")
              },
            },
          },
          [_vm._m(0)]
        ),
      ]),
    ]),
    _vm.project
      ? _c("div", [
          _c(
            "div",
            { staticClass: "stats" },
            [
              _c("h3", [_vm._v(_vm._s(_vm.project.name) + " is on track.")]),
              _c("el-progress", {
                attrs: {
                  percentage: 40,
                  "stroke-width": 10,
                  "show-text": false,
                  color: _vm.customColors,
                },
              }),
              _c("div", { staticClass: "graphs" }, [
                _c(
                  "div",
                  { staticClass: "graph-container" },
                  [
                    _c("span", [_vm._v("Milestones")]),
                    _c("el-progress", {
                      attrs: {
                        width: 80,
                        type: "circle",
                        percentage: 100,
                        status: "success",
                        color: _vm.customColors,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "graph-container" },
                  [
                    _c("span", [_vm._v("Time")]),
                    _c("el-progress", {
                      attrs: {
                        width: 80,
                        type: "circle",
                        percentage: 64,
                        color: _vm.customColors,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "graph-container" },
                  [
                    _c("span", [_vm._v("Tasks")]),
                    _c("el-progress", {
                      attrs: {
                        width: 80,
                        type: "circle",
                        percentage: 29,
                        color: _vm.customColors,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "graph-container" },
                  [
                    _c("span", [_vm._v("Finance")]),
                    _c("el-progress", {
                      attrs: {
                        width: 80,
                        type: "circle",
                        percentage: 89,
                        color: _vm.customColors,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("i", { staticClass: "el-icon-switch-button" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }