var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "fade", mode: "out-in" } },
    [
      _vm.loading
        ? _c("fullscreen-loader", {
            attrs: { loading: _vm.loading, message: "Loading" },
          })
        : !_vm.authenticated
        ? _c("access-login", { on: { onLogin: _vm.cacheAccessCard } })
        : _c("access-authenticated", {
            attrs: { project: _vm.project },
            on: { onLogout: _vm.logout },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }